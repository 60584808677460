import { ResultDTO } from "../organisms/result/domain/result";

export type Callback = (waitAndRetry: () => void, status: string) => void;
export type StatusesCallbacks = Record<string, Callback | null>;

const pollStatus = function (
  statusUrl: string,
  statusesCallbacks: StatusesCallbacks,
  defaultCallback: Callback | null,
  timeoutCallback: () => void = () => undefined
): void {
  let attempts = 25;

  const fetchStatus = function () {
    if (attempts === 0) {
      timeoutCallback();

      return;
    }
    attempts--;

    const request = new XMLHttpRequest();
    request.open("GET", statusUrl, true);
    request.setRequestHeader("Content-type", "application/json");
    request.setRequestHeader("Authorization", `Bearer ${process.env.GATSBY_API_TOKEN}`);
    request.onload = function () {
      if (!this.response) {
        waitAndRetry();

        return;
      }

      const { status } = JSON.parse(this.response as string) as ResultDTO;
      const callback = statusesCallbacks[status] ?? defaultCallback;

      if (!callback) {
        waitAndRetry();

        return;
      }

      callback(waitAndRetry, status);
    };

    request.send();
  };

  const waitAndRetry = function () {
    setTimeout(fetchStatus, 3000);
  };

  fetchStatus();
};

export default pollStatus;
